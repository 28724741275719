@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

:root{
  --background-color: #a9a599;
  --accent-color: #009c9b;
  --secondary-color: white;
  --font-color: #543a16;
  --navbar-height: 90px;
  --footer-height: 200px;
}

*{
  box-sizing: border-box;
}

.link:hover{
  text-decoration: underline;
}

img{
  display: block;
}

body {
  margin: 0;
  font-family: "Roboto", cursive, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  color: var(--font-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
